var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "popup", staticClass: "popup_dim2" }, [
      _c(
        "div",
        {
          staticClass: "popup_wrap",
          staticStyle: { width: "800px", height: "520px" },
        },
        [
          _c(
            "button",
            {
              staticClass: "layer_close",
              on: {
                click: function ($event) {
                  return _vm.closePopup()
                },
              },
            },
            [_vm._v("close")]
          ),
          _c("div", { staticClass: "popup_cont" }, [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(_vm._s(_vm.$t("comp.300"))),
            ]),
            _c(
              "div",
              { staticClass: "content_box" },
              [
                _c("DEXT5Upload", {
                  ref: "dextupload",
                  attrs: {
                    id: _vm.component_id,
                    single: _vm.single,
                    category: _vm.category,
                    width: "100%",
                    height: "200px",
                    "file-info": _vm.value,
                    "category-info": _vm.catList,
                    "file-ext": _vm.fileExt,
                    "file-size": _vm.fileSize,
                  },
                  on: { completed: _vm.uploadCompleted },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                on: {
                  click: function ($event) {
                    return _vm.closePopup()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("comp.302")))]
            ),
          ]),
        ]
      ),
    ]),
    _c(
      "button",
      {
        staticClass: "button blue lg mt10 layer_open",
        on: {
          click: function ($event) {
            _vm.openPopup()
            return false
          },
        },
      },
      [_vm._v(_vm._s(_vm.$t("comp.302")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }