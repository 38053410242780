<template>
  <div>
    <!-- container_ekmtc -->
    <div class="popup_dim2" ref="popup">
      <div class="popup_wrap" style="width:800px; height:520px;">
        <button class="layer_close" @click="closePopup()">close</button>
        <div class="popup_cont">
          <h1 class="page_title">{{ $t('comp.300') }}</h1>
          <div class="content_box">
            <DEXT5Upload
              ref="dextupload"
              :id="component_id"
              :single="single"
              :category="category"
              width="100%"
              height="200px"
              @completed="uploadCompleted"
              :file-info="value"
              :category-info="catList"
              :file-ext="fileExt"
              :file-size="fileSize"
            />
          </div>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" @click="closePopup()">{{ $t('comp.302') }}</a>
        </div>
      </div>
    </div>

    <button class="button blue lg mt10 layer_open" @click="openPopup(); return false">{{ $t('comp.302') }}</button>
  </div>
</template>

<script>
export default {
  components: {
    DEXT5Upload: () => import('@/components/DEXT5Upload')
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    single: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    catList: {
      type: Array,
      default: function () {
        return []
      }
    },
    fileExt: {
      type: String,
      default: '',
      required: false
    },
    fileSize: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    },
    category () {
      return this.catList.length !== 0
    }
  },
  mounted () {
  },
  methods: {
    uploadCompleted (fileInfo) {
      this.$emit('completed', fileInfo)
    },
    openPopup () {
      const st = $('html').scrollTop()
      $(this.$refs.popup).data('scroll-top', st).fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePopup: function () {
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()

      const st = $(this.$refs.popup).data('scroll-top')
      $(this.$refs.popup).fadeOut()
      $('body,html').removeAttr('style')
      $('html').scrollTop(st)
      return false
    }
  }
}
</script>
<style>
.DEXT_fiVe_UP_ly_wrapper{
  z-index: 99999999 !important;
}
</style>
